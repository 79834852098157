
// The actions that require changes to be saved to the database

import {Area, Bay, MapController, MapEventHandler, MapRenderer} from "../index";
import {SERVER_URL} from "../../../Constants";
import axios from "axios";
import MapStore, {MapObjectEntityType} from "../Map/MapStore";
import {store} from "../../../Models/Store";
import {AreaEntity, BayEntity, ImportVersionEntity, LinkEntity, NodeEntity} from "../../../Models/Entities";
import alertToast from "../../../Util/ToastifyUtils";
import {MapType} from "../Map/MapController";
import Path from "../Map/MapObjects/Path/Path";
import {MapObjectType} from "../Map/MapObjects/MapObject";
import {SaveCommand, SaveCommandType} from "./ChangeTypes/SaveCommand";
import DynamicScaleObjectHelper from "../Map/MapStateHandlerHelpers/DynamicScaleObjectHelper";


interface SaveInputRequest {
	importVersionId: string;
	lockKey: string;
	actionType: SaveCommandType;
	data: any;
}

interface SaveResponse {
	error: string
}

export default class ChangeTracker {
	private readonly controller: MapController;
	private readonly mapStore: MapStore;
	private readonly mapEventHandler: MapEventHandler;
	private readonly mapRenderer: MapRenderer;

	public savingCount = 0;

	constructor(controller: MapController) {
		this.controller = controller;
		this.mapStore = this.controller.getMapLookup();
		this.mapEventHandler = this.controller.getEventHandler();
		this.mapRenderer = this.controller.getMapRenderer();
	}

	public get isSaving() {
		return this.savingCount > 0;
	}

	async addChange(change: SaveCommand) {
		this.savingCount++;

		await this.saveChanges(change);

		this.savingCount--;
	}

	private async saveChanges(change: SaveCommand) {
		if (change === undefined) {
			return;
		}

		const importVersionId = this.mapStore.getImportVersion().id;
		const lockKey = store.getMapLockSession(importVersionId)?.lockKey;

		try {
			this.displayAutoSaveIndicator();

			const result = await axios.post(`${SERVER_URL}/api/entity/MapEntity/save`, {
				importVersionId: importVersionId,
				lockKey: lockKey,
				actionType: SaveCommandType[change.changeType],
				data: change.getData(),
			});

			this.handleResponse(result.data);
		} catch (e) {
			alertToast('Unable to save changes', 'error');
			console.error('Error saving changes', e);
		} finally {
			this.updateLastSaveTime();
		}
	}

	private handleResponse(response: any) {
		if (response.error) {
			console.error(response.error);
			alertToast(response.error, 'error');
			return;
		}

		if (!!response.updatedEntities) {
			let selectedEntityId: string | undefined;
			let selectedEntityType: MapType | undefined;

			response.updatedEntities.forEach((e: any) => {
				const entityType = e.entityType;
				const entity = e.entity;
				const isDeleted = e.isDeleted;

				let model;
				switch (entityType) {
					case "Link":
						model = new LinkEntity(entity);
						break;
					case "Bay":
						model = new BayEntity(entity);
						break;
					case "Area":
						model = new AreaEntity(entity);
						break;
					case "Node":
						model = new NodeEntity(entity);
						break;
					case 'ImportVersion':
						this.handleImportVersion(new ImportVersionEntity(entity));

						return; /* We don't have to do anything with the import version */
					case 'DynamicConnections':
						this.mapStore.setEntryAndExitNodeIds(entity.entryNodeIds, entity.exitNodeIds);
						return;
					default:
						console.warn(`Unknown entity type ${entityType} when handling save response`);
						return;
				}

				// Update entity should only be called on objects that are map types
				if (this.updateEntity(model, entityType, isDeleted)) {
					// Only one object should return true
					selectedEntityId = model.getModelId();
					selectedEntityType = entityType;
				}
			});

			// Update the map objects panel after we finish processing
			this.mapEventHandler.emit('onUpdateMapObjectsPanel',
				selectedEntityId,
				selectedEntityType as MapObjectType
			);

			// Update the dynamic scale object display
			DynamicScaleObjectHelper.updateDynamicObjectDisplay(this.controller, false);

			// Rerender the map
			this.mapRenderer.rerender();
		}
	}

	/**
	 * Updates the entity in the interface
	 *
	 * If the object needs to be reselected in the layers panel, it will return true from this method
	 *
	 * @param entity
	 * @param entityType
	 * @param isDeleted
	 * @private
	 */
	private updateEntity<T extends MapObjectEntityType>(entity: T, entityType: MapType, isDeleted?: boolean): boolean {
		const state = isDeleted === true ? 'DELETED' : entity.state;
		const isEntityInStore = this.mapStore.getEntity(entity);

		if (state === 'DELETED') {
			this.mapStore.deleteEntity(entity);
		} else if (state == 'NEW_OBJECT' && !isEntityInStore) {
			this.mapStore.createEntity(entity); // Need to make sure the entity hasn't already been created in the store
		} else {
			// update the entity in the map store
			this.mapStore.updateEntity<T>(entity, (e: T) => {
				entity.resetAndAddNewErrors(e.getErrors().map(err => err.errorMessage));
				entity.resetAndAddNewWarnings(e.getWarnings().map(warn => warn.warningMessage));

				return entity;
			});
		}

		this.handleRenderingObject(entity, entityType, isDeleted);

		// Update the properties panel if the entity is currently selected (May need to be changed once more
		// scenarios are added)
		if (this.controller.getHighlightedEntityId() === entity.id && isDeleted !== true) {
			// If the current entity is selected, we need to rerender the properties panel
			// this.mapEventHandler.emit('onPropertiesPanel', entityType, entity);
			this.mapEventHandler.setMapEventState('selector', {
				mapObject: this.mapStore.getMapObjectByEntity(
					entity,
					entityType
				)
			});

			return true;
		}

		return false;
	}

	private handleRenderingObject<T extends MapObjectEntityType>(entity: T, entityType: MapType, isDeleted?: boolean) {
		let mapObject = this.mapStore.getMapObjectByEntity(
			entity,
			entityType.toLowerCase() === 'link' ? 'path' : entityType
		);

		const isEntityDeleted = isDeleted === true;

		// If the entity is deleted, we need to remove the map object from the renderer
		if (isEntityDeleted) {
			if (!!mapObject) {
				this.mapRenderer.removeObject(mapObject.getId());
			}
			return;
		}

		let newMapObject;
		switch (entityType.toLowerCase()) {
			case "link":
				newMapObject = new Path(entity as LinkEntity, this.mapRenderer, this.mapStore);
				break;
			case "area":
				newMapObject = new Area(entity as AreaEntity, this.mapRenderer, this.mapStore);
				break;
			case "bay":
				newMapObject = new Bay(entity as BayEntity, this.controller);
				break;
			default:
				return undefined;
		}

		// If there is no map object to replace the old one, we can skip rendering
		if (!newMapObject) {
			return;
		}

		if (!!mapObject) {
			this.mapRenderer.removeObject(mapObject.getId());
		}

		// If the entity is highlighted, we need to re-highlight the new entity
		if (this.controller.getHighlightedEntityId() === entity.id) {
			newMapObject.setHighlighted(true, false);
		}

		this.mapRenderer.addObject(newMapObject);
	}

	private handleImportVersion(entity: ImportVersionEntity) {
		let importVersion = this.controller.getImportVersion()

		importVersion.pathEdited = importVersion.pathEdited || entity.pathEdited;
		importVersion.bayEdited = importVersion.bayEdited || entity.bayEdited;
		importVersion.areaEdited = importVersion.areaEdited || entity.areaEdited;

		// Emit the event to update the menu bar
		this.mapEventHandler.emit('onImportVersionStatusChanged', {
			pathEdited: importVersion.pathEdited,
			bayEdited: importVersion.bayEdited,
			areaEdited: importVersion.areaEdited,
			bayPublished: importVersion.bayPublished,
			areaPublished: importVersion.areaPublished,
			pathPublished: importVersion.pathPublished,
		}, true);
	}

	private displayAutoSaveIndicator() {
		this.mapEventHandler.emit('onAutoSave', false);
	}

	private updateLastSaveTime() {
		this.mapEventHandler.emit('onAutoSave', true, new Date());
	}
}